
.login-warp {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: url("http://auto.hntxrj.com/bg.png");
  .logon-form {
    // width: 400/80rem;
    background: #00000033;
    padding: 40/80rem;
    border-radius: 10/80rem;
  }
}
