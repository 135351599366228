.login-warp {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: url("http://auto.hntxrj.com/bg.png");
}
.login-warp .logon-form {
  background: #00000033;
  padding: 0.5rem;
  border-radius: 0.125rem;
}
